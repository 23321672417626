<template>
  <div><LicenseType /></div>
</template>

<script>
import LicenseType from '@/modules/license-type/LicenseType.vue'

export default {
  components: {
    LicenseType,
  },
}
</script>

<style>

</style>
