<template>
  <div class="enterprise-page">
    <div
      v-if="$can('create', 'licensetype')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="createLicenseType()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="enterprise-table"
      class="module-table mt-4"
      :items="items"
      :fields="fields"
      hover
      :filter="filter"
      :filter-included-fields="filterOn"
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailItemTable"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensetype')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/license-type/general', 'LicenseType')"
        />
      </template>
      <template
        v-if="$can('delete', 'licensetype')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/license-type/general', fetchTable: 'LicenseType' })"
        />
      </template>
      <template #cell(licensing_agency)="row">
        <div class="j-c-center">
          {{ row.item.licensing_agency ? row.item.licensing_agency.name : 'Não especificado' }}
        </div>
      </template>
      <template #cell(documentos)="row">
        <div class="j-c-center">
          {{
            row.item.documents_conditioning ? row.item.documents_conditioning.filter(arr => arr.document && arr.isActive).length : 0
          }}
          <VSIcons
            class="ml-1"
            name="FileFillGradient"
          />
        </div>
      </template>
      <template #cell(condicionantes)="row">
        <div class="j-c-center">
          {{
            row.item.documents_conditioning ? row.item.documents_conditioning.filter(arr => arr.conditioning && arr.isActive).length : 0
          }}
          <VSIcons
            class="ml-1"
            name="TickFileFillGradient"
          />
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="enterprise-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BTable,
  BButton,
  BFormCheckbox,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    VSModal,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Título',
          key: 'subject',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Sigla',
          key: 'acronyms',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Descrição',
          key: 'description',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Órgão Licenciador',
          key: 'licensing_agency',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Documentos',
          key: 'documentos',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Condicionantes',
          key: 'condicionantes',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'licenseType/getLicenseTypes' }),
  },
  created() {
    this.fetchLincenseTypes()
  },
  methods: {
    ...mapActions({ fetchLincenseTypes: 'licenseType/fetchLincenseTypes' }),
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    createLicenseType() {
      this.$router.push('tipos-licenca/adicionar/detalhes')
    },
    openModal() {
      this.showModal('Import')
    },
  },
}
</script>
